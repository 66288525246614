<!--
   Created by Terence on 2023/2/21 - 16:34
-->
<template>
  <div class="auction">
    <div class="auction-content">
      <div class="auction-content-left">
        <div class="auction-content-left--top">
          <span class="price">
            <span class="price-unit">¥</span>
            <span class="price-value">{{
              auctionDetailData.currentPrice || auctionDetailData?.startingPrice
            }}</span>
          </span>
          <span class="auction-txt">竞拍该作品</span>
        </div>
        <div class="auction-content-left--bottom">
          {{
            auctionDetailData.isFutureSelling
              ? auctionDetailData.startTimeString
              : auctionDetailData.finishTimeString
          }}
        </div>
        <!--          <div-->
        <!--            class="auction-content-left&#45;&#45;bottom"-->
        <!--            v-if="auctionInfo.confirmTime"-->
        <!--          >-->
        <!--            卖家需要在 {{ auctionInfo.confirmTime }} 前确认-->
        <!--          </div>-->
      </div>
      <div class="auction-content-right">
        <!--确认支付-->
        <DuButton type="recommend" size="small" @click="handleBuy"
          >去出价</DuButton
        >

        <!--                    <DuButton type="primary" size="large" @click="handleShowImgs"-->
        <!--                    >{{ actionConfig.uiConfig.btnInfo.text }}</DuButton-->
        <!--                    >-->

        <!--          <main-button v-if="isMeMaxBid" @onInvokeApp="handlePay" text="支付" />-->
        <!--          <main-button v-else @onInvokeApp="handleBid" text="出价" />-->
      </div>
    </div>

    <div class="owneraction" v-if="isOwner">
      <MainButton
        inactivated
        full
        text="请去App操作"
        @onInvokeApp="onInvokeApp"
      />
    </div>
  </div>
</template>

<script setup>
import { ref, defineProps, defineEmits, inject } from "vue";
import { storeToRefs } from "pinia";
import {
  Icon as VanIcon,
  Popup as VanPopup,
  showFailToast,
  showLoadingToast,
  showDialog,
  closeToast,
} from "vant";
import MainButton from "@/components/MainButton";
import { getCountdownTime } from "@/utils/time";
import { DuButton } from "dangoui";
import { getQuery } from "@/utils/url";
import { useRouter } from "vue-router";

const router = useRouter();

const emit = defineEmits(["onInvokeApp"]);

// getCountdownTime(auctionInfo.finishTime) + "截拍";
const auctionDetailData = inject("auctionDetailData");
const isOwner = inject("isOwner");

const onInvokeApp = () => {
  emit("onInvokeApp");
};

const handleBuy = () => {
  const { tokenId } = getQuery();
  const tradeId = auctionDetailData.value.id;
  router.push(
    `/product-detail?tokenId=${tokenId}&tradeId=${tradeId}&tradeType=auction`
  );
};
</script>

<style lang="scss" scoped>
.auction {
  display: flex;
  flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 99;
  padding: 16rpx 30rpx 68rpx 30rpx;
  width: 100%;
  background-color: white;
  &-tips {
    display: flex;
    align-items: center;
    width: 100%;
    &-label {
      margin-right: 8rpx;
      padding: 12rpx 0;
      font-size: 24rpx;
    }
  }
  &-content {
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: space-between;
    &-left {
      &--top {
        display: flex;
        align-items: center;
        .price {
          display: flex;
          align-items: baseline;
          &-unit {
            color: var(---color_third, #7247dc);
            text-align: center;
            font-family: "Roboto";
            font-size: 32rpx;
            font-style: normal;
            font-weight: 700;
            line-height: 48rpx; /* 150% */
          }
          &-value {
            color: var(---color_third, #7247dc);
            text-align: center;
            font-family: "Roboto";
            font-size: 40rpx;
            font-style: normal;
            font-weight: 700;
            line-height: 52rpx; /* 130% */
          }
        }
        .auction-txt {
          margin-left: 8rpx;
          color: var(---text_primary, #000);
          font-feature-settings: "clig" off, "liga" off;
          /* Heavy/H6 */
          font-family: "PingFang SC";
          font-size: 28rpx;
          font-style: normal;
          font-weight: 500;
          line-height: 44rpx; /* 157.143% */
        }
      }
      &--bottom {
        color: var(---text_third, rgba(0, 0, 0, 0.5));
        font-size: 20rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 22rpx; /* 110% */
        text-transform: capitalize;
      }
    }
    &-right {
    }
  }
}

.owneraction {
  margin-top: 12rpx;
  width: 100%;
}

.hugewidth600 {
  width: 600rpx;
}
.hugewidth {
  width: 354rpx;
}
.hugewidth2 {
  width: 394rpx;
}
</style>

<!--v-model	min max step-->
