<template>
  <div class="token-owner-list" v-if="detailData.type === 'SFT'">
    <div class="owner">
      <template v-if="isMainTokenSelling && !props.detailData.isMotherToken">
        <div class="selling">
          <span class="selling-left">发布者正在批量发售该作品</span>
          <span class="selling-right">
            <span class="selling-right-price"
              >¥{{ originTokenInfo.tradeInfo.price }}</span
            >
            <DuButton type="recommend" size="small" full @click="handleBuy">
              去购买
            </DuButton>
          </span>
        </div>
        <div class="dash-line" />
      </template>
      <div class="owner-list" @click="handleToOwnersPage">
        <div class="owner-list-avatars">
          <img
            :class="`owner-list-avatars-item ${`owner-list-avatars-item-${index}`}`"
            v-for="(item, index) in avatarList"
            :key="index"
            :src="item.avatar + '!mfit_w480_h480_jpg'"
          />
        </div>
        <div class="owner-list-content">
          <span class="owner-list-content-count">{{
            detailData.ownerCount
          }}</span
          >人同时拥有该作品
          <span class="mine" v-if="getIsLogin && myAvatarList.length">
            <img class="mine-avatar" :src="myAvatarList?.[0]?.avatar" alt="" />
            <span class="mine-txt"
              >我拥有<span class="mine-txt-count"
                >#{{ myTokenInfo.tokenIndex }}</span
              ></span
            >
            <ArrowRight :style="{ marginLeft: '1.0666666666666667rem' }" />
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {
  defineProps,
  defineEmits,
  computed,
  onMounted,
  ref,
  defineExpose,
} from "vue";
import { useRouter } from "vue-router";
import { DuButton } from "dangoui";
import { storeToRefs } from "pinia";
import DuIcon from "dangoui";
import ArrowRight from "@/components/ArrowRight/index.vue";
import NftApi from "@/apis";
import { useUsersStore } from "@/store/user";
import { TRADE_STATUS } from "@/constant/contract";

const userStore = useUsersStore();
const router = useRouter();

const { userInfo, getIsLogin } = storeToRefs(userStore);

// const items = [
//   { name: "档案1", coverImg: "", creator: "ytc666", price: "120" },
//   { name: "档案2", coverImg: "", creator: "ytc666", price: "120" },
//   { name: "档案3", coverImg: "", creator: "ytc666", price: "120" },
//   { name: "", coverImg: "", creator: "ytc666", price: "" },
// ];

const props = defineProps({
  detailData: {
    type: Object,
  },
});

const emits = defineEmits(["handleToOwnerList"]);

const avatarList = ref([]);
const myAvatarList = ref([]);
const myTokenInfo = ref({});
const originTokenInfo = ref({});
const isMainTokenSelling = ref(false);

const items = computed(() => {
  const isOwner = userInfo.value.userId === props.owner?.userId;
  // const isOwner = true;
  const tempList = [
    {
      name: null,
      coverImg: "",
      creator: { displayName: props.owner?.displayName },
      estimatePrice: null,
    },
  ];

  const resultList = isOwner
    ? [...props.belongedCharacters, ...tempList]
    : props.belongedCharacters;
  return resultList;
});

const handleBuy = () => {
  router.push(
    `/product-detail?tokenId=${originTokenInfo.value.id}&tradeType=buyout&tradeId=${originTokenInfo.value?.tradeInfo?.tradeOrderId}`
  );
};

const handleToOwnersPage = () => {
  emits("handleToOwnerList");
};

const initOwnerList = async () => {
  const { data } = await NftApi.getOwnerList({
    tokenId: props.detailData.originTokenId,
    limit: 3,
    offset: 0,
  });

  const tempList = data?.list?.map((item) => {
    return {
      avatar: item.owner.avatarUrl,
    };
  });

  avatarList.value = tempList;
};

const initMyOwnerInfo = async () => {
  if (getIsLogin.value) {
    const { data } = await NftApi.getOwnerList({
      tokenId: props.detailData.originTokenId,
      limit: 100,
      offset: 0,
      onlyMine: true,
    });

    const tempList = data?.list?.map((item) => {
      return {
        avatar: item.owner.avatarUrl,
      };
    });

    myAvatarList.value = tempList.reverse();
    myTokenInfo.value = data?.list?.[0];
  }
};

const initTradeInfo = async () => {
  const { data } = await NftApi.getTokenDetail({
    tokenId: props.detailData.originTokenId,
  });

  const tradeInfo = data.token?.tradeInfo;

  isMainTokenSelling.value =
    tradeInfo?.type === "SALE" && tradeInfo?.status === TRADE_STATUS.SELLING;

  originTokenInfo.value = data?.token;
};

const initAllData = async () => {
  initOwnerList();
  initMyOwnerInfo();
  initTradeInfo();
};

defineExpose({
  initAllData,
});

onMounted(() => {
  initAllData();
});
</script>

<style lang="scss" scoped>
.token-owner-list {
  margin-top: 24rpx;
  .owner {
    display: flex;
    width: 690rpx;
    padding: 32rpx;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 32rpx;
    background: #fff;
    box-shadow: 0 8rpx 40rpx 0 rgba(0, 0, 0, 0.06);
    .selling {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-left {
      }
      &-right {
        display: flex;
        align-items: center;
        &-price {
          margin-right: 16rpx;
          color: #000;
          font-family: "Roboto";
          font-size: 28rpx;
          font-style: normal;
          font-weight: 700;
          line-height: 44rpx; /* 157.143% */
        }
      }
    }
    &-list {
      display: flex;
      align-items: center;
      &-avatars {
        position: relative;
        width: 88rpx;
        display: flex;
        align-items: center;
        &-item {
          position: absolute;
          width: 40rpx;
          height: 40rpx;
          border-radius: 40rpx;
          border: 1rpx solid rgba(0, 0, 0, 0.1);
        }
        &-item-0 {
          z-index: 0;
        }
        &-item-1 {
          z-index: 1;
          left: 24rpx;
        }
        &-item-2 {
          z-index: 2;
          left: 48rpx;
        }
      }
      &-content {
        display: flex;
        align-items: center;
        color: rgba(0, 0, 0, 0.5);
        font-size: 24rpx;
        font-style: normal;
        font-weight: 400;
        line-height: 36rpx; /* 150% */
        &-count {
          margin: 0 8rpx;
          color: #000;
          font-family: "Roboto";
          font-size: 24rpx;
          font-style: normal;
          font-weight: 700;
          line-height: 36rpx; /* 150% */
        }
        .mine {
          display: flex;
          align-items: center;
          color: #000;
          font-size: 24rpx;
          font-style: normal;
          font-weight: 400;
          line-height: 36rpx; /* 150% */
          &-avatar {
            margin-left: 16rpx;
            width: 40rpx;
            height: 40rpx;
            border-radius: 40rpx;
            border: 1rpx solid rgba(0, 0, 0, 0.1);
            object-fit: cover;
          }
          &-txt {
            margin-left: 8rpx;
            &-count {
              margin-left: 8rpx;
              font-weight: 500;
            }
          }
        }
      }
    }
  }
  .dash-line {
    margin: 16rpx 0;
    width: 626rpx;
    border-top: 2rpx dashed rgba(0, 0, 0, 0.12);
  }
}
</style>
