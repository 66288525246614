<template>
  <div class="ov">
    <div class="ov-single">
      <span class="ov-single-label">作品ID</span
      ><span class="ov-single-id"
        >{{ detailData.originTokenId }} - #{{ detailData.tokenIndex }}</span
      >
    </div>
    <div class="ov-single">
      <span class="ov-single-label">该作品由</span>
      <span class="ov-single-author"
        >{{ detailData?.publisher?.displayName }} &nbsp;创作</span
      >
    </div>
    <div class="ov-list" v-if="items?.length">
      <div class="item" v-for="(item, index) in items" :key="index">
        <div class="item-name">
          <div class="label">所属档案</div>
          <div class="value" v-if="item.name">
            <img
              class="profilecover"
              :src="item.cover + '!mfit_w480_h480_jpg'"
              alt=""
            />
            <span class="value-text otext">{{ item.name }}</span>
          </div>
          <div class="value value-placeholder" v-else @click="handleInvoke">
            <DuIcon size='3.2rem' name="plus-circle" />
            <div class="text">去添加</div>
          </div>
        </div>
        <!--        <div class="item-creator">-->
        <!--          <div class="label">创作者</div>-->
        <!--          <div class="value">-->
        <!--            <span class="value-text otext">{{-->
        <!--              item.creator?.displayName || "/"-->
        <!--            }}</span>-->
        <!--          </div>-->
        <!--        </div>-->
        <div class="item-price">
          <div class="label">购入价格</div>
          <div class="value otext" v-if="item.estimatePrice !== null">
            ¥{{ detailData.estimateFee }}
          </div>
          <div class="value value-placeholder" v-else @click="handleInvoke">
            <DuIcon size='3.2rem' name="edit" />
            <div class="text">去填写</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { defineProps, defineEmits, computed } from "vue";
import { storeToRefs } from "pinia";
import DuIcon from "dangoui";
import { useUsersStore } from "@/store/user";

const userStore = useUsersStore();

const { userInfo } = storeToRefs(userStore);

// const items = [
//   { name: "档案1", coverImg: "", creator: "ytc666", price: "120" },
//   { name: "档案2", coverImg: "", creator: "ytc666", price: "120" },
//   { name: "档案3", coverImg: "", creator: "ytc666", price: "120" },
//   { name: "", coverImg: "", creator: "ytc666", price: "" },
// ];

const props = defineProps({
  detailData: {
    type: Object,
  },
  belongedCharacters: {
    type: Array,
    default: () => [],
  },
  owner: {
    type: Object,
  },
});

const emits = defineEmits(["onInvokeApp"]);

const items = computed(() => {
  const isOwner = userInfo.value.userId === props.owner?.userId;
  // const isOwner = true;
  const tempList = [
    {
      name: null,
      coverImg: "",
      creator: { displayName: props.owner?.displayName },
      estimatePrice: null,
    },
  ];

  const resultList = isOwner
    ? [...props.belongedCharacters, ...tempList]
    : props.belongedCharacters;
  return resultList;
});

const handleInvoke = () => {
  emits("onInvokeApp");
};
</script>

<style lang="scss" scoped>
.ov {
  margin-top: 24rpx;
  &-single {
    display: flex;
    align-items: center;
    margin-bottom: 24rpx;
    &-label {
      margin-right: 8rpx;
      color: rgba(0, 0, 0, 0.5);
      font-size: 20rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 22rpx; /* 110% */
      text-transform: capitalize;
    }
    &-id {
      color: #000;
      font-size: 24rpx;
      font-style: normal;
      font-weight: 500;
      line-height: 36rpx; /* 150% */
    }
    &-author {
      color: rgba(0, 0, 0, 0.5);
      font-size: 20rpx;
      font-style: normal;
      font-weight: 400;
      line-height: 22rpx; /* 110% */
      text-transform: capitalize;
    }
  }
  &-list {
    margin-top: 48rpx;
    //background: skyblue;
    .item {
      margin-top: 40rpx;
      display: flex;
      align-items: center;
      justify-content: space-between;
      &-name,
      &-creator,
      &-price {
        padding-left: 16rpx;
        .label {
          color: rgba(0, 0, 0, 0.5);
          font-size: 20rpx;
          font-style: normal;
          font-weight: 400;
          line-height: 22rpx; /* 110% */
        }
        .value {
          display: flex;
          align-items: center;
          margin-top: 8rpx;
          color: #000;
          font-size: 28rpx;
          font-style: normal;
          font-weight: 500;
          line-height: 44rpx; /* 157.143% */
          &-text {
            width: 200rpx;
          }
        }
        .value-placeholder {
          display: flex;
          align-items: center;
          color: rgba(0, 0, 0, 0.5);
          font-size: 28rpx;
          font-style: normal;
          font-weight: 500;
          line-height: 44rpx; /* 157.143% */
          .text {
            margin-left: 8rpx;
          }
        }
        .profilecover {
          margin-right: 8rpx;
          border-radius: 6rpx;
          overflow: hidden;
          width: 40rpx;
          height: 40rpx;
          justify-content: center;
          align-items: center;
          /* 💙 x:4 ; y:4 ; Blur=8（设卡投影） */
          box-shadow: 8rpx 8rpx 16rpx 0 rgba(0, 0, 0, 0.06);
        }
      }
      &-name {
        padding-left: 0;
        flex: 0 0 40%;
        //background-color: orangered;
      }
      &-creator {
        flex: 0 0 35%;
        //background-color: pink;
      }
      &-price {
        flex: 0 0 25%;
        //background-color: yellowgreen;
      }
    }
    .item:nth-child(1) {
      margin-top: 0;
    }
  }
}

.otext {
  overflow: hidden;

  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
